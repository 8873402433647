import styled, { keyframes } from 'styled-components';
import { Form } from '@unform/web';

import Input from '@/components/Inputs/DefaultInput';
import { colors } from '@/styles/GlobalStyle';

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  max-width: 1440px;

  height: fit-content;

  padding: 108px;

  box-sizing: border-box;

  h2 {
    font-size: 24px;
    font-weight: 700;
    line-height: 35px;

    color: ${colors.gray600};
    width: 100%;
    max-width: 401px;
  }

  p {
    max-width: 400px;
    font-size: 14px;
    line-height: 20px;

    color: ${colors.gray300};

    margin-top: 6px;
  }

  @media (max-width: 500px) {
    margin: 0 16px;
    padding: 40px;
  }
`;

export const OuvidoriaForm = styled(Form)`
  margin-top: 31px;

  display: flex;
  flex-direction: column;

  max-width: 400px;
  width: 100%;

  height: fit-content;

  .buttons {
    display: flex;
    justify-content: space-between;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 31px;

    width: 100%;
    max-width: 180px;

    outline: none;
    border: none;

    border-radius: 8.4px;

    font-size: 16px;
    font-weight: 500;

    height: 40px;
    color: white;

    background-color: ${colors.blue400};

    &:hover {
      color: white;
      background-color: #052373;
      transition: 0.2s;
    }

    &.outlined {
      background-color: white;
      border: 1px solid ${colors.blue400};
      color: ${colors.blue400};

      &:hover {
        background-color: ${colors.blue400};
        color: white;
      }
    }
  }
`;

export const CNPJTelefoneFormSection = styled.div`
  display: flex;
  justify-content: space-between;

  width: 100%;

  div + div {
    margin-left: 8px;
  }

  @media (max-width: 430px) {
    flex-direction: column;

    div + div {
      margin-left: 0px;
    }
  }
`;

export const InputCPFouCNPJ = styled(Input)`
  width: 100%;
  max-width: 190px;

  @media (max-width: 430px) {
    max-width: unset;
  }
`;

export const InputTelefone = styled(Input)`
  width: 100%;
`;

export const ErrorMessage = styled.span`
  display: block;
  color: ${colors.red};
  margin-top: 8px;
  font-size: 12px;
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const Spinner = styled.div`
  border: 2px solid ${colors.white};
  border-top: 4px solid ${colors.blue400};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: ${rotate} 0.5s linear infinite;
`;
