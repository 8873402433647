import React, { useRef, useCallback, useState } from 'react';
import { navigate } from 'gatsby';
import * as yup from 'yup';
import { FormHandles } from '@unform/core';
import axios from 'axios';

import Input from '@/components/Inputs/DefaultInput';
import TextArea from '@/components/Inputs/TextArea';

import { validateCPForCNPJ } from '@/utils/cpfOrCnpjValidator/cnpjAndCpf';
import { getValidationErrors } from '@/utils/getValidationErrors';

import {
  Section,
  OuvidoriaForm,
  InputCPFouCNPJ,
  InputTelefone,
  CNPJTelefoneFormSection,
  ErrorMessage,
  Spinner,
} from './styles';

interface IForm {
  nome: string;
  cpfOrcnpj: string;
  telefone: string;
  email: string;
  mensagem: string;
}

interface IProps {
  setEnviado: React.Dispatch<React.SetStateAction<boolean>>;
}

function mountEmailData(data: IForm) {
  return {
    from: {
      email: 'site@transportegeneroso.com',
      name: 'Site Transporte Generoso',
    },
    to: [
      {
        email: 'ouvidoria@generoso.com.br',
        name: 'Ouvidoria Generoso',
      },
    ],
    subject: `Ouvidoria site Generoso - ${data.nome}`,
    text: `
      Nome: ${data.nome} 
      E-mail: ${data.email}
      Telefone: ${data.telefone} 
      CPF/CNPJ: ${data.cpfOrcnpj}
      
      Mensagem: ${data.mensagem}
    `,
    html: `
      <div>
        <p>Nome: ${data.nome}</p> 
        <p>E-mail: ${data.email}</p>
        <p>Telefone: ${data.telefone}</p>
        <p>CPF/CNPJ: ${data.cpfOrcnpj}</p>
          <br>
        <p>Mensagem: ${data.mensagem}</p>
      </div>
    `,
  };
}

const SectionComponent: React.FC<IProps> = ({ setEnviado }): JSX.Element => {
  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);
  const [sendEmailErrorMessage, setSendEmailErrorMessage] = useState('');

  const sendEmail = useCallback(async (formData: IForm) => {
    try {
      const emailData = mountEmailData(formData);

      await axios.post('/api/send-message/email', {
        emailData,
      });
    } catch (err) {
      console.error(err);

      throw new Error('Ocorreu um erro ao enviar o email!');
    }
  }, []);

  const handleSubmit = useCallback(
    async (formData: IForm) => {
      try {
        formRef.current?.setErrors({});
        setLoading(true);

        const schema = yup.object().shape({
          nome: yup
            .string()
            .required('Entre com um nome válido.')
            .min(3, 'Nome precisa ter pelo menos 3 caracteres.')
            .max(35, 'Nome pode ter no máximo 35 caracteres.')
            .test('has-numbers', 'Digite apenas letras.', (nome) => {
              return !nome?.match(/\d+/);
            }),
          email: yup
            .string()
            .required('Entre com um e-mail.')
            .email('Entre com um e-mail válido.'),
          cpfOrcnpj: yup
            .string()
            .required('Entre com um CPF ou CNPJ.')
            .test('has-letters', 'Entre com um CPF/CNPJ válido.', (cpfOrcnpj) =>
              validateCPForCNPJ(cpfOrcnpj)
            ),
          telefone: yup
            .string()
            .test('has-letters', 'Insira apenas numeros.', (telefone) => {
              return !!telefone?.match(/\(\d{2}\)\s\d{4,5}\-\d{4}/);
            })
            .test('valid-number', 'Insira um número válido', (telefone) => {
              return !!telefone?.match(
                /^\((?:[14689][1-9]|2[12478]|3[1234578]|5[1345]|7[134579])\) (?:[2-8]|9[1-9])[0-9]{3}\-[0-9]{4}$/
              );
            })
            .required('Entre com um telefone.'),
          mensagem: yup.string().required('Insira a mensagem.'),
        });

        await schema.validate(formData, {
          abortEarly: false,
        });

        await sendEmail(formData);
        setEnviado(true);
      } catch (error) {
        console.log('error', error.message);

        if (error.message === 'Ocorreu um erro ao enviar o email!') {
          setSendEmailErrorMessage(
            '*Houve um erro ao tentar enviar seus dados para o RH, por gentileza tente novamente mais tarde.'
          );
        }

        const validationErrors = getValidationErrors(error);

        formRef.current?.setErrors(validationErrors);
      } finally {
        setLoading(false);
      }
    },
    [setEnviado, sendEmail, setSendEmailErrorMessage]
  );

  return (
    <Section>
      <h2>Ouvidoria</h2>
      <p>A nossa Ouvidoria recebe reclamações, sugestões e elogios.</p>
      <p>
        Quando achar necessário você pode (e deve!) recorrer à Ouvidoria
        Generoso, estamos prontos para atendê-lo!
      </p>

      <OuvidoriaForm ref={formRef} onSubmit={handleSubmit}>
        <Input
          name="nome"
          labelName="Nome completo"
          placeholder="Nome completo"
        />

        <CNPJTelefoneFormSection>
          <InputCPFouCNPJ
            name="cpfOrcnpj"
            labelName="CPF/CNPJ"
            placeholder="CPF/CNPJ"
            mask={['999.999.999-99', '99.999.999/9999-99']}
            type="tel"
          />

          <InputTelefone
            name="telefone"
            labelName="Telefone"
            placeholder="Telefone"
            mask={['(99) 9999-9999', '(99) 99999-9999']}
            type="tel"
          />
        </CNPJTelefoneFormSection>

        <Input
          name="email"
          labelName="E-mail"
          placeholder="E-mail"
          type="email"
        />

        <TextArea
          name="mensagem"
          labelName="Mensagem"
          placeholder="Mensagem..."
        />

        <div className="buttons">
          <button
            type="button"
            className="outlined"
            onClick={() => navigate('/atendimento')}
          >
            Voltar
          </button>

          <button type="submit">
            {loading ? <Spinner /> : 'Enviar mensagem'}
          </button>
        </div>

        {sendEmailErrorMessage && (
          <ErrorMessage>
            Houve um erro ao tentar enviar seus dados para a Ouvidoria. Por
            gentileza tente novamente mais tarde
          </ErrorMessage>
        )}
      </OuvidoriaForm>
    </Section>
  );
};

export default SectionComponent;
