import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '@/components/Layout';

import {
  PageWrapper,
  PageContainer,
  SectionWrapper,
} from '@/styles/pages/atendimento/ouvidoria';

import Section from '@/components/Pages/Ouvidoria/Section';
import ConfirmationPopup from '@/components/Pages/Ouvidoria/ConfirmationPopup';
import SEO from '@/components/SEO';
import { CMS_URL } from '@/config';

const OuvidoriaPage = (): JSX.Element => {
  const [enviado, setEnviado] = useState(false);

  const query = graphql`
    query {
      directus {
        site_pages(filter: { slug: { _contains: "atendimento-ouvidoria" } }) {
          seo {
            title
            description
            keywords
            image {
              id
            }
          }
        }
      }
    }
  `;

  const { directus } = useStaticQuery(query);

  const SEOData = directus.site_pages[0].seo;

  return (
    <>
      <SEO
        pageTitle="Ouvidoria"
        title={SEOData.title}
        description={SEOData.description}
        image={`${CMS_URL}/assets/${SEOData.image}`}
        keywords={SEOData.keywords}
      />
      <Layout>
        <PageWrapper>
          <PageContainer>
            <SectionWrapper>
              {!enviado && <Section setEnviado={setEnviado} />}
            </SectionWrapper>
            <SectionWrapper>
              {enviado && <ConfirmationPopup setEnviado={setEnviado} />}
            </SectionWrapper>
          </PageContainer>
        </PageWrapper>
      </Layout>
    </>
  );
};

export default OuvidoriaPage;
