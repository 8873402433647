import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import { FiAlertCircle } from 'react-icons/fi';

import { Container, ErrorContainer, ErrorMessage } from './styles';

interface ITextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  name: string;
  labelName: string;
}

const TextArea: React.FC<ITextAreaProps> = ({
  name,
  placeholder,
  labelName,
  ...rest
}) => {
  const [active, setActive] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue: (ref, value) => {
        if (ref && ref.current) {
          ref.current.value = value;
        }
      },
      clearValue: (ref) => {
        if (ref && ref.current) {
          ref.current.value = '';
        }
      },
    });
  }, [fieldName, registerField]);

  const handleInputBlur = useCallback(() => {
    setActive(false);

    setIsFilled(!!inputRef.current?.value);
  }, []);

  return (
    <>
      <Container haveError={!!error} active={active || isFilled}>
        <textarea
          name={name}
          defaultValue={defaultValue}
          ref={inputRef}
          placeholder={placeholder}
          onFocus={() => setActive(true)}
          onBlur={handleInputBlur}
          {...rest}
        />

        <label>{labelName}</label>

        {error && isFilled && (
          <ErrorContainer title={error}>
            <FiAlertCircle size={20} color="#F81414" />
          </ErrorContainer>
        )}
      </Container>

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </>
  );
};

export default TextArea;
