import styled, { css } from 'styled-components';

import { colors } from '@/styles/GlobalStyle';

import Tooltip from '@/components/Tooltip';

interface ContainerProps {
  active: boolean;
  haveError: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;

  height: fit-content;
  border-radius: 8px;
  position: relative;

  margin-top: 8px;
  padding-top: 10px;

  background-color: #ffffff;
  transition: 0.3s background-color ease-in-out;

  border: 1px solid ${colors.gray300};

  textarea {
    width: 100%;
    height: 190px;

    resize: none;

    position: relative;
    padding: 0 1rem;
    box-sizing: border-box;

    border: none;

    font-size: 14px;
    font-weight: 400;
    line-height: normal;

    background-color: transparent;
    color: #282828;
    outline: none;

    box-shadow: 0px 4px 10px 0px transparent;
    transition: 0.3s background-color ease-in-out, 0.2s padding ease-in-out;
    -webkit-appearance: none;
  }

  label {
    position: absolute;
    top: 24px;
    left: 16px;
    font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    color: #ffffff;
    opacity: 0;
    pointer-events: none;
    transition: 0.2s all ease-in-out;
  }

  textarea::-webkit-input-placeholder {
    color: ${colors.gray400};
  }

  textarea::-moz-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }

  textarea:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }

  textarea:-moz-placeholder {
    color: rgba(255, 255, 255, 0.8);
  }

  ${(props) =>
    !props.active &&
    css`
      &:hover {
        background-color: rgba(255, 255, 255, 0.45);
      }
    `}

  ${(props) =>
    props.active &&
    css`
      border: 1px solid ${colors.blue400};
      background-color: #ffffff;
      padding-top: 20px;

      textarea {
        padding: 10px 16px 8px 16px;
      }

      textarea::-webkit-input-placeholder {
        color: #ffffff;
      }

      label {
        top: 5px;
        opacity: 1;
        color: ${colors.gray600};
        z-index: 100;
      }
    `}

  /** HAVE ERROR */
  ${(props) =>
    props.haveError &&
    css`
      border: 1px solid ${colors.red};

      label {
        color: ${colors.red};
      }
    `}
`;

export const ErrorContainer = styled(Tooltip)`
  svg {
    position: absolute;
    right: 16px;
    top: 12px;

    height: 20px;
  }
`;

export const ErrorMessage = styled.span`
  margin-left: 5px;
  font-size: 12px;
  height: 10px;

  color: ${colors.red};
`;
