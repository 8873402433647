import React from 'react';

import { ConfirmationPopup } from './styles';

import ConfirmationIcon from '@/images/paginas/ouvidoria/confirmation-icon.svg';

interface IProps {
  setEnviado: React.Dispatch<React.SetStateAction<boolean>>;
}

const ConfirmationPopupComponent: React.FC<IProps> = ({
  setEnviado,
}): JSX.Element => {
  return (
    <ConfirmationPopup>
      <img src={ConfirmationIcon} alt="Ícone de confirmação" />

      <h2>Sua mensagem foi enviada para ouvidoria</h2>

      <button onClick={() => setEnviado(false)}>OK</button>
    </ConfirmationPopup>
  );
};

export default ConfirmationPopupComponent;
