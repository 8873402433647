import { colors } from '@/styles/GlobalStyle';
import styled from 'styled-components';

export const ConfirmationPopup = styled.div`
  width: 100%;
  max-width: 464px;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  text-align: center;

  min-height: 80vh;

  h2 {
    font-size: 20px;
    line-height: 30px;

    font-weight: 400;

    max-width: 282px;

    color: ${colors.gray300};

    margin-top: 29px;
  }

  p {
    margin-top: 18px;
  }

  button {
    margin-top: 40px;

    width: 100%;
    max-width: 71px;

    height: 40px;

    background-color: ${colors.blue400};
    color: white;

    font-weight: 500;

    outline: none;
    border: none;

    border-radius: 8.4px;

    &:hover {
      color: white;
      background-color: #052373;
      transition: 0.2s;
    }
  }

  @media (max-width: 320px) {
    margin: 0 8px;
  }
`;
