import styled from 'styled-components';

export const PageWrapper = styled.div`
  width: 100%;
  max-width: 100vw;

  height: 100%;

  display: flex;
  justify-content: center;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 1440px;
  height: fit-content;
`;

export const SectionWrapper = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
  max-width: 1380px;
`;
